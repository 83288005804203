// @flow
import React, { Component } from 'react';
import BoardingUpdate from '../Components/BoardingUpdate';
import './BoardingUpdates.css';

class BoardingUpdates extends Component {
    render() {
        return (
            <div className="boardingUpdates">
            {this.props.updates.map(update => (
                <BoardingUpdate update={update}/>
            ))}
            </div>
        );
    }
}

export default BoardingUpdates; 